import Footer from "./Footer";
import Booking from "./Booking";
import NavBar from "./Header";
import Services from "./Services";
import "./OurService.scss";


const OurServices = () => {

  return (
    <div >
      <div className="our-services">
        <NavBar />
      </div>
      <Booking />
      <Services />
      <Footer />
    </div>
  );
};

export default OurServices;