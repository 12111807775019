import ContactUs from "./ContactUs";
import ContractProcedure from "./ContractProcedure";
import './ContactComponent.scss'
import Footer from "./Footer";

const ContactComponents = () => {
  return (
    <div className="contact-component">
      <ContactUs />
      <ContractProcedure />
      <Footer />
    </div>
  );
};

export default ContactComponents;