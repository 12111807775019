import { Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import './BookASection.scss'

const BookASection = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(["translation", "de"])
  return (
    <Container fluid className="book-a-section">
      <Row className="book-a-section-body">
        <Col>
          <h1 className="book-a-section-title">{t('booking.title')}</h1>
        </Col>
        <Col>
          <span className="book-a-section-description">{t('booking.subtitle')}</span>
        </Col>
        <Col className="d-flex justify-content-center pt-5">
          <div className="book-a-section-button" onClick={() => navigate('contact-us')}>
            <span className="book-a-section-button-text">{t('booking.button')}</span>
          </div>
        </Col>
      </Row>

    </Container>
  )
}

export default BookASection