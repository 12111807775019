
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AboutUs from "./components/about-us/AboutUs";
import ContactComponents from "./components/ContactComponents";
import QuestionsComponent from "./components/QuestionComponent";
import HomePage from "./components/HomePage";
import OurServices from "./components/OurServices";
import Jobs from "./components/Jobs";

const RoutePath = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<OurServices />} />
        <Route path="/contact-us" element={<ContactComponents />} />
        <Route path="/faqs" element={<QuestionsComponent />} />
        {/*         <Route path="/jobs" element={<Jobs />} />
 */}      </Routes>
    </Router>
  );
};

export default RoutePath;