import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import hizelus from '../assets/images/hizelus-eng-logo.png'
import './Header.scss';

function NavBar() {
  const { t } = useTranslation(['translation', 'de']);
  const [selected, setSelected] = useState('');
  const navigate = useNavigate();

  return (
    <Container fluid className='nav-bar-container'>
      <Navbar bg="transparent" className='header-navbar' expand="lg">
        <div>
          <Navbar.Brand href="#"><img src={hizelus} className='img-fluid' style={{ height: '150px', width: '150px' }} alt="" /></Navbar.Brand>
        </div>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" style={{ maxHeight: '100px', justifyContent: 'space-between' }}>
          <div>
          </div>
          <div>
            <Nav
              navbarScroll
              defaultActiveKey="/"
            >
              <Nav.Link eventKey='home' className={selected === 'home' ? 'selected' : ''} onClick={() => { navigate('/'); setSelected('home') }}>{t('header.home')}</Nav.Link>
              <Nav.Link eventKey='about' className={selected === 'about' ? 'selected' : ''} onClick={() => { navigate('/about-us'); setSelected('about') }}>{t('header.about')}</Nav.Link>
              <Nav.Link eventKey='service' className={selected === 'service' ? 'selected' : ''} onClick={() => { navigate('/services'); setSelected('service') }}>
                {t('header.services')}
              </Nav.Link>
              <Nav.Link eventKey='faq' className={selected === 'faq' ? 'selected' : ''} onClick={() => { navigate('/faqs'); setSelected('faq') }}>
                {t('header.faqs')}
              </Nav.Link>
              {/* <Nav.Link eventKey='jobs' className={selected === 'jobs' ? 'selected' : ''} onClick={() => { navigate('/jobs'); setSelected('jobs') }}>
                {t('header.jobs')}
              </Nav.Link> */}
            </Nav>
          </div>
          <div className='mr-auto'>
            <Button onClick={() => navigate('/contact-us')} variant="outline-success">{t('header.contact')}</Button>
          </div>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
}

export default NavBar;


