import NavBar from "./Header";
import Jumbotron from "./Jumbotron";
import "./ContactUs.scss";
import ContactForm from "./ContactForm";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";

const ContactUs = () => {
  const { t } = useTranslation(["translation", "de"]);

  return (
    <div className="contactus">
      <NavBar />
      <Jumbotron title={t('contactUs.title')} description={t('contactUs.subtitle1')} />
      <ContactForm className="contact-form">
        <Container>
          <div className="contact-form-body">
            <span className="contact-form-title">{t('contactUs.title1')}</span>
            <span className="contact-subtitle">{t('contactUs.subtitle')}: </span>
            <span className="contactUs-reach-us">{t('contactUs.title3')}</span>
            <Row className="contact-details">
              <Col>
                <span className="contact-details-address">{t('contactUs.address')}: </span>
                <span className="contact-address">{t('contactUs.address1')}</span>
              </Col>
              <Col>
                <span className="contact-details-phone">{t('contactUs.phone')}: </span>
                <span className="contact-phone">{t('contactUs.phone1')}</span>
              </Col>
              <Col>
                <span className="contact-details-email">{t('contactUs.email')}: </span>
                <span className="text-decoration-underline">{t('contactUs.email1')}</span>
              </Col>
            </Row>
          </div>
        </Container>
      </ContactForm>


    </div>
  );
};

export default ContactUs;