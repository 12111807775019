const BackToTopIcon = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="28" cy="28" r="27" stroke="#010101" stroke-width="2" />
    <g clip-path="url(#clip0_48_1455)">
      <path d="M20 28L21.41 29.41L27 23.83V36H29V23.83L34.58 29.42L36 28L28 20L20 28Z" fill="#010101" />
    </g>
    <defs>
      <clipPath id="clip0_48_1455">
        <rect width="24" height="24" fill="white" transform="translate(16 16)" />
      </clipPath>
    </defs>
  </svg>
);

export default BackToTopIcon;