import "./Jumbotron.scss";

interface Props {
  title: string;
  description: string;
}

//

const Jumbotron = ({ title, description }: Props) => {

  return (
    <div className="jumbotron">
      <span className="jumbotron-title">{title}</span>
      <span className="jumbotron-description">
        {description}
      </span>
    </div>
  );
};

export default Jumbotron;