import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Container } from "react-bootstrap";
import "./QuestionsAccordion.scss";
import ExpandIcon from "../assets/icons/expand-icon";
import { useTranslation } from "react-i18next";

const QuestionsAccordion = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const { t } = useTranslation(["translation", "de"]);

  const handleChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container className="questions-accordion">
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography >
            {t('faqs.question1')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {t('faqs.answer1')}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography>{t('faqs.question2')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {t('faqs.answer2')}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography>
            {t('faqs.question3')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {t('faqs.answer3')}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography>
            {t('faqs.question4')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {t('faqs.answer4')}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={<ExpandIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography>
            {t('faqs.question5')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {t('faqs.answer5')}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          expandIcon={<ExpandIcon />}
          aria-controls="panel6bh-content"
          id="panel4bh-header"
        >
          <Typography>
            {t('faqs.question6')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {t('faqs.answer6')}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel7"}
        onChange={handleChange("panel7")}
      >
        <AccordionSummary
          expandIcon={<ExpandIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography>
            {t('faqs.question7')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {t('faqs.answer7')}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

export default QuestionsAccordion;