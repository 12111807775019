import { useTranslation } from "react-i18next";
import Jumbotron from "./Jumbotron";
import NavBar from "./Header";
import "./FAQs.scss";

const Faqs = () => {
  const { t } = useTranslation(["translation", "de"]);

  return (
    <div className="faqs">
      <NavBar />
      <Jumbotron title={t('faqs.title')} description={t('faqs.subtitle')} />
    </div>
  );
};

export default Faqs;

