/* eslint-disable import/no-anonymous-default-export */
export default {
  translation: {
    global: {
      title: 'Best IT Solution for your Business',
      subtitle:
        'We are a team of professionals who are passionate about technology and innovation.',
      button: 'Book a free consultation',
    },
    about: {
      title: 'About Us',
      subtitle:
        'We are a team of professionals who are passionate about technology and innovation.',
    },
    services: {
      title: 'Services',
      subtitle:
        'We are a team of professionals who are passionate about technology and innovation.',
    },
    contact: {
      title: 'Contact Us',
      subtitle:
        'We are a team of professionals who are passionate about technology and innovation.',
    },
    faqs: {
      title: 'FAQs',
      subtitle:
        'Have questions about our services? Explore our frequently asked questions below to find answers to common inquiries. If you don’t see your question listed, don’t hesitate to reach out to us directly. Our knowledgeable team is here to provide you with the information you need.',
      question1: 'How long does it take to develop a custom software solution?',
      answer1:
        'The development timeline for a custom software solution can vary depending on the complexity and scope of the project. During our initial consultation, we assess your requirements and provide an estimated timeline based on factors such as functionality, integrations, and testing. Our team works efficiently to deliver high-quality solutions within agreed-upon timeframes.',
      question2:
        'Can you provide ongoing support and maintenance after the project is completed?',
      answer2:
        'Absolutely! We offer ongoing support and maintenance services to ensure the smooth operation of your software solution. Our team provides timely updates, bug fixes, and security patches to keep your software up-to-date and optimized. We offer different support packages tailored to your specific needs, ensuring that you have peace of mind and continued assistance post-launch.',
      question3:
        'How do you ensure the security and confidentiality of my data?',
      answer3:
        'We prioritize the security and confidentiality of your data. We follow industry best practices and implement robust security measures to protect your information from unauthorized access, breaches, and data loss. We can discuss specific security requirements during our initial discussions and tailor our solutions to meet your data protection needs.',
      question4:
        'Can you integrate the software solution with existing systems or third-party applications?',
      answer4:
        "Yes, we specialize in seamless integrations. Our team has extensive experience in integrating software solutions with various systems and third-party applications. Whether it's connecting to an existing CRM, payment gateway, or other essential software, we ensure smooth data flow and interoperability to maximize the value of your software solution.",
      question5:
        'How do you ensure that the software solution is user-friendly?',
      answer5:
        'User experience is a top priority for us. Our design and development process incorporates user-centric principles, such as conducting user research and usability testing. We aim to create intuitive and user-friendly interfaces that provide a seamless and enjoyable experience for your end-users. We also value your feedback throughout the development process to refine the user experience and ensure satisfaction.',
      question6: 'What is your approach to project management?',
      answer6:
        'We follow a comprehensive project management approach that ensures transparency, effective communication, and timely delivery. Our team utilizes industry-standard methodologies such as Agile or Waterfall, depending on the nature of the project. We provide regular progress updates, encourage client feedback, and prioritize your satisfaction throughout the project lifecycle.',
      question7: 'What is your pricing structure?',
      answer7:
        'We offer competitive pricing based on the specific requirements of each project. As every project is unique, we assess factors such as complexity, scope, and timeline to provide a tailored quote. Rest assured, our pricing is transparent, and we strive to deliver value and ROI for your investment in our services.',
    },
    footer: {
      title:
        'Building Digital Success Stories from Concept to Reality - we transform brilliant ideas into great products that grow our clients business at scale',
      userfulLinks: {
        title: 'Useful Links',
        home: 'Home',
        about: 'About Us',
        services: 'Our Services',
        contact: 'Contact Us',
        faqs: 'FAQs',
      },
      contact: {
        title: 'Contact Us',
        address: 'Address: Wandsbeker Chaussee 212, 22089 Hamburg',
        phone: 'Phone: +4917642056661',
        email: 'contact@hizelus.com',
      },
      social: {
        title: 'Social',
        facebook: 'Facebook',
        twitter: 'Twitter',
        instagram: 'Instagram',
        linkedin: 'Linkedin',
      },
    },
    header: {
      home: 'Home',
      about: 'About Us',
      services: 'Our Services',
      contact: 'Contact Us',
      faqs: 'FAQs',
      jobs: 'Trainings',
    },
    coreServices: {
      title: 'Core Services',
      subtitle:
        'Welcome to Hizelus Engineering - Your Premier Software Consulting Partner Delivering Cutting-Edge Solutions in Mobile Development, Web Development, Design, Branding, and Data Management At Hizelus Engineering, we are passionate about providing innovative and customized software solutions to help businesses thrive in the digital age. With our expertise in mobile development, web development, design, branding, and data management, we empower organizations to achieve their goals and stay ahead of the competition.',
      service1: {
        title: 'Design',
        description:
          'Transforming Ideas into Stunning Realities. Our talented team crafts visually appealing designs that capture your brands essence, resonate with your audience, and elevate your brand identity. From logos to websites, we create impactful designs that leave a lasting impression.',
      },
      service2: {
        title: 'Mobile Development',
        description:
          'Our skilled mobile development team creates intuitive and feature-rich applications for iOS and Android platforms. Leveraging the latest technologies and industry best practices, we build mobile solutions that offer seamless user experiences and drive engagement.',
      },
      service3: {
        title: 'Web Development',
        description:
          'From responsive websites to complex web applications, our web development services cater to diverse business needs. We combine cutting-edge technologies, creative design, and robust functionality to deliver websites that captivate audiences and deliver tangible results.',
      },
      service4: {
        title: 'Branding',
        description:
          'Your brand is the heart and soul of your business, and we understand its importance. Our talented designers collaborate closely with you to craft visually stunning designs that align with your brand identity. We create captivating user interfaces, logos, and marketing collateral that leave a lasting impression on your target audience.',
      },
      service5: {
        title: 'Data Engineering',
        description:
          'Data is the backbone of modern businesses, and efficient data management is crucial for success. Our data management solutions ensure that your valuable information is organized, secured, and readily accessible. From data integration and warehousing to analytics and reporting, we optimize your data processes to drive informed decision-making.',
      },
      more_services: {
        text: 'More on our services',
      },
    },
    feedBack: {
      title: 'What Our Clients Say',
      step1: {
        name: 'Philipp Schneiderwind',
        position: 'CEO, Company',
        feedback:
          '"Working with Hizelus Engineering has been a game-changer for our business. Their expertise in web development helped us create a visually stunning and user-friendly website that has significantly improved our online presence. Their team’s professionalism, attention to detail, and timely delivery exceeded our expectations. We highly recommend their services."',
      },
      step2: {
        name: 'Ogbonna Chinedu Emmanuel',
        position: 'CEO, Company',
        feedback:
          '"I can’t thank Hizelus Engineering enough for their outstanding mobile app development services. They took the time to understand our requirements and delivered a highly functional and intuitive app that has transformed the way our customers engage with our business. The level of professionalism and technical expertise displayed by their team was impressive. I wouldn’t hesitate to work with them again."',
      },
      step3: {
        name: 'Emma Duru',
        position: 'CEO, Company',
        feedback:
          '"Choosing Hizelus Engineering for our software consulting needs was one of the best decisions we made. Their consultants provided invaluable insights and guidance, helping us streamline our processes and identify opportunities for growth. Their expertise and dedication to our success were evident throughout the entire project. We are extremely satisfied with the results and look forward to future collaborations."',
      },
    },
    ourWork: {
      title: 'Our Work',
      subtitle:
        'At Hizelus Engineering, we take pride in our ability to deliver exceptional software solutions to our clients. With expertise in mobile development, web development, design, branding, and data management, we create innovative and customized solutions tailored to meet your unique business needs. Our dedicated team of professionals collaborates closely with you to understand your goals and challenges, ensuring that our work exceeds your expectations and drives tangible results for your business.',
      work1: {
        title: 'Project 1',
        subtitle:
          'We are a team of professionals who are passionate about technology and innovation.',
      },
      work2: {
        title: 'Project 2',
        subtitle:
          'We are a team of professionals who are passionate about technology and innovation.',
      },
      work3: {
        title: 'Project 3',
        subtitle:
          'We are a team of professionals who are passionate about technology and innovation.',
      },
      work4: {
        title: 'Project 4',
        subtitle:
          'We are a team of professionals who are passionate about technology and innovation.',
      },
      work5: {
        title: 'Project 5',
        subtitle:
          'We are a team of professionals who are passionate about technology and innovation.',
      },
      work6: {
        title: 'Project 6',
        subtitle:
          'We are a team of professionals who are passionate about technology and innovation.',
      },
    },
    booking: {
      title: 'Empower Your Business with Software Solutions',
      subtitle:
        'Ready to unlock the potential of software solutions for your business? Book a consultation with Hizelus Engineering to discuss your specific needs, challenges, and objectives. Our expert team will provide tailored recommendations and insights to help you make informed decisions and propel your business forward. Don`t miss out on the opportunity to revolutionize your business—schedule your consultation today.',
      button: 'Book a free consultation',
    },
    coreValue: {
      title: 'Our Core Values',
      subtitle:
        'At Hizelus Engineering, our core values of integrity, innovation, and excellence form the foundation of our company culture and drive our commitment to delivering exceptional software solutions. These values shape the way we conduct business, collaborate with clients, and approach every project, ensuring that we consistently deliver high-quality results.',
      value1: {
        title: 'Integrity',
        subtitle:
          'Integrity is at the heart of everything we do. We prioritize honesty, transparency, and ethical behavior in our interactions with clients, partners, and team members. Trust is the cornerstone of successful relationships, and we strive to earn and maintain the trust of our stakeholders through our unwavering commitment to integrity.',
      },
      value2: {
        title: 'Innovation',
        subtitle:
          'We embrace innovation as a driving force behind our success. We foster a culture of curiosity, exploration, and forward thinking, encouraging our team to push boundaries and find creative solutions to complex challenges. By staying at the forefront of emerging technologies and industry trends, we empower our clients with innovative software solutions that position them for growth and competitive advantage.',
      },
      value3: {
        title: 'Teamwork',
        subtitle:
          'We are a team of professionals who are passionate about technology and innovation.',
      },
      value4: {
        title: 'Excellence',
        subtitle:
          'Excellence is our standard. We are dedicated to delivering nothing short of exceptional results in every aspect of our work. From the quality of our code to the user experience of our applications, we leave no stone unturned in our pursuit of excellence. We continuously strive to exceed expectations, meet deadlines, and provide unparalleled service to our clients.',
      },
      value5: {
        title: 'Customer Focus',
        subtitle:
          'We are a team of professionals who are passionate about technology and innovation.',
      },
    },
    whoWeAre: {
      title: 'Who We Are - Your Trusted Software Consulting Partner',
      subtitle:
        'We are a team of experienced software professionals committed to delivering exceptional results. With deep industry knowledge and a customer-centric approach, we provide customized software solutions that drive growth and efficiency. As your trusted partner, we listen to your needs, leverage cutting-edge technologies, and deliver on time and within budget. Let us be your reliable software consulting partner—contact us today to unlock the power of technology for your business.',
    },
    whyWeDoIt: {
      title: 'Why We Do It - Empowering Businesses through Software Solutions',
      subtitle:
        'We are driven by a clear purpose: to empower businesses through innovative software solutions. With a focus on excellence and a passion for making a positive impact, we leverage advanced technologies to streamline operations, enhance efficiency, and drive sustainable growth. As your trusted partner, we are committed to helping your business thrive in the digital landscape.',
    },
    team: {
      title: 'Meet Our Team - Experts Dedicated to Your Success',
      subtitle:
        'Our team of skilled professionals is passionate about delivering exceptional software solutions. With diverse expertise and a commitment to excellence, we collaborate closely with you to turn your ideas into reality. From customized mobile applications to stunning websites, our team is here to bring your vision to life and ensure your success.',
      member1: {
        name: 'Hilary Ogalagu',
        position: 'CEO, Software Engineer, Hizelus',
      },
      member2: {
        name: 'Narender Vaddepelly',
        position: 'CTO - Senior Software Developer, Hizelus',
      },
      member3: {
        name: 'Kenneth Obi',
        position: 'FullStack Developer, Hizelus',
      },
      member4: {
        name: 'Vanessa laturnus',
        position: 'Content Manager, Hizelus',
      },
      member5: {
        name: 'John Doe',
        position: 'CEO, Company',
      },
      member6: {
        name: 'John Doe',
        position: 'CEO, Company',
      },
    },
    stayConnected: {
      title: 'Stay Connected with the community',
      subtitle:
        'Join our vibrant community at Hizelus and unlock endless opportunities for engagement, learning, and growth. Connect with like-minded individuals, access valuable resources, and stay up-to-date with the latest trends and insights. Together, let`s thrive in the world of technology.',
      button: 'Subscribe',
    },
    OurServices: {
      title: 'OUR SERVICES',
      title1: 'Design',
      subtitle1:
        'we understand the power of design in making a strong impact. Our talented designers work closely with you to create visually stunning designs that capture your brand`s essence and resonate with your audience. From captivating logos and engaging marketing collateral to intuitive user interfaces, our design solutions elevate your brand and leave a lasting impression.',
      title2: 'Mobile Development',
      subtitle2:
        'In today’s mobile-centric world, having a strong presence on smartphones is crucial. Our expert mobile development team specializes in creating innovative and user-friendly applications for iOS and Android platforms. Leveraging the latest technologies, we craft mobile solutions that enhance user experiences, drive engagement, and position your business for success in the mobile landscape.',
      title3: 'Web Development',
      subtitle3:
        'Your website is often the first touchpoint for potential customers, making it essential to have an impactful online presence. At Hizelus Engineering, we offer web development services that go beyond just aesthetics. Our team combines creative design with technical expertise to deliver responsive websites and dynamic web applications. With a focus on user experience and seamless functionality, we ensure your web presence delivers results.',
      title4: 'Branding',
      subtitle4:
        'A strong brand identity is the cornerstone of business success. Our branding experts work collaboratively with you to develop a comprehensive branding strategy that resonates with your target audience. From logo design and visual identity creation to defining your brand’s messaging framework, we help you establish a compelling brand that stands out and creates a lasting connection with your customers.',
      title5: 'Digital Marketing',
      subtitle5:
        'In a fast-paced digital landscape, embracing technology is essential for business growth. Our digital solutions are tailored to your unique needs, integrating technology to optimize processes, enhance customer experiences, and drive business performance. From e-commerce platforms and content management systems to automation and digital marketing solutions, we help you leverage digital opportunities and stay ahead of the competition.',
      title6: 'Consulting',
      subtitle6:
        'Navigating the complexities of software initiatives can be challenging. Our experienced consultants provide valuable guidance and strategic advice to help you make informed decisions. Whether you need assistance with project planning, technology selection, or process optimization, we offer expert insights and industry best practices to ensure the success of your software initiatives.',
      title7: 'Data Management',
      subtitle7:
        'Data is the lifeblood of modern businesses, and effective data management is crucial. Our data management solutions encompass data integration, warehousing, analytics, and reporting. We help you harness the power of your data by organizing, securing, and analyzing it, enabling you to make data-driven decisions and gain a competitive edge in your industry.',
    },
    HowWeDoIt: {
      title: 'How We will help you',
      subtitle:
        'We are a team of professionals who are passionate about technology and innovation.',
      step1: {
        title: 'Create your product concept',
        subtitle:
          'We work closely with you to understand your goals, target audience, and market landscape. Our team of experienced professionals will assist you in refining your product concept, transforming it into a well-defined vision that aligns with your business objectives. Through strategic brainstorming sessions and in-depth analysis, we shape your ideas into a solid foundation for your product.',
      },
      step2: {
        title: 'Test out your business idea',
        subtitle:
          'Before diving into full-scale development, it’s crucial to validate your business idea. We help you conduct market research, analyze competitors, and perform user testing to ensure that your product idea is viable and has a strong market fit. Through prototype development and user feedback iterations, we refine your concept to maximize its potential for success.',
      },
      step3: {
        title: 'Build your product',
        subtitle:
          'Once your product concept is validated, we move into the development phase. Our skilled team of developers, designers, and project managers work collaboratively to build your product with precision and efficiency. By leveraging cutting-edge technologies and industry best practices, we create a scalable, robust, and user-centric solution that meets your business objectives.',
      },
      step4: {
        title: 'launch your product',
        subtitle:
          'We guide you through the product launch process, helping you create a strategic launch plan and execute it effectively. From market positioning to marketing strategies, we provide guidance to generate buzz and maximize the impact of your product launch. Our aim is to help you make a strong entry into the market and achieve early success.',
      },
      step5: {
        title: 'Reduce your risks',
        subtitle:
          'Risk management is at the core of our approach. We employ proven methodologies and industry expertise to identify and mitigate risks throughout the entire product development lifecycle. By conducting thorough assessments, implementing quality assurance measures, and adhering to industry standards, we ensure that your product development journey is smooth and secure.',
      },
    },
    contactForm: {
      title: 'Contact Us',
      title1: 'YOUR DETAILS',
      subtitle:
        'I would like to recieve promotional emails and monthly newsletters',
      button: 'submit',
    },
    contactUs: {
      title: 'Contact Us',
      subtitle:
        'Ready to take the next step? We’re excited to hear from you. Contact Hizelus Engineering today to begin your software journey and explore how our expertise can help you achieve your goals. Whether you have questions, need more information, or want to discuss your specific requirements, our friendly team is here to assist you. Reach out to us via phone, email, or by filling out the contact form below, and let’s embark on this transformative journey together.',
      address: 'Address',
      address1: 'Eidelstedter Dorfstr. 3 22527 Hamburg Germany',
      phone: 'Phone',
      phone1: '+4917642056661',
      email: 'Email',
      email1: 'hilary@hizelus.com',
      title1: 'Partner with us to make it work',
      subtitle1:
        'Get in touch with Hizelus Engineering and unlock the power of collaborative innovation. Our dedicated team is here to understand your unique needs, provide expert guidance, and deliver customized software solutions that drive your success. Whether you have a question, want to discuss a project, or simply need more information, we are eager to hear from you. Contact us today and let’s embark on a journey of digital transformation together.',
      button: 'submit',
      title3: 'you can also reach us',
    },
    whatsNext: {
      title: 'So What’s Next?',
      step1: {
        title: 'Initial Consultation',
        subtitle:
          'Let’s get started by scheduling an initial consultation. We’ll discuss your specific needs, goals, and vision for your software project. This is an opportunity for us to understand your requirements and provide insights on how we can best assist you.',
      },
      step2: {
        title: 'Project Proposal',
        subtitle:
          'Based on the information gathered during the consultation, we’ll prepare a detailed project proposal outlining the scope, timeline, and cost of the project. This proposal will serve as a roadmap for our collaboration and provide a clear understanding of what to expect.',
      },
      step3: {
        title: 'Requirements Gathering',
        subtitle:
          'Once the project proposal is approved, we’ll conduct thorough requirements gathering sessions. Our team will work closely with you to identify and document all the functional and technical requirements of your software solution. This step ensures that we have a comprehensive understanding of your needs and can deliver a tailored solution.',
      },
      step4: {
        title: 'Design and Planning',
        subtitle:
          'With the requirements in place, our talented designers and architects will begin creating the blueprint for your software solution. This involves wireframing, prototyping, and creating the user interface (UI) and user experience (UX) design. We’ll collaborate with you to refine the design and ensure it aligns with your branding and objectives.',
      },
      step5: {
        title: 'Development and Testing',
        subtitle:
          'Once the design is finalized, our skilled developers will start coding and building your software solution. We follow industry best practices and agile development methodologies to ensure transparency, efficiency, and high-quality results. Throughout the development process, rigorous testing and quality assurance measures will be applied to guarantee a reliable and robust solution.',
      },
      step6: {
        title: 'Client Collaboration',
        subtitle:
          'We believe in the power of collaboration, and your involvement is essential during the development phase. We’ll provide regular updates, seek your feedback, and incorporate any necessary adjustments to ensure that the final product meets your expectations. Your input and insights are invaluable in shaping the solution.',
      },
      step7: {
        title: 'Delivery and Support',
        subtitle:
          'When your software solution is complete and thoroughly tested, we’ll deliver it to you, ensuring a smooth transition. We’ll provide comprehensive documentation and training to help you maximize the benefits of your new software. Our support doesn’t end there - we offer ongoing maintenance, updates, and technical support to ensure the long-term success of your solution.',
      },
    },
    aboutUsStatus: {
      customer: 'Amazing Customers',
      team: 'Team Members',
      products: 'Products Launched',
      community: 'Community Members',
    },
  },
}
