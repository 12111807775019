import OurWork from "./OurWork";
import './HomePage.scss';
import FeedBack from "./FeedBack";
import CoreServices from "./CoreServices";
import Booking from "./Booking";
import BookASection from "./BookASection";
import Footer from "./Footer";
import NavBar from "./Header";

const HomePage = () => {
  return (
    <div className="home-content" >
      <NavBar />
      <Booking />
      <CoreServices />
      <FeedBack />
      {/* <OurWork /> */}
      <BookASection />
      <Footer />
    </div>
  );
}

export default HomePage;