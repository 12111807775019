import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AboutUsJumboIcon } from "../../assets/icons/aboutus-icon";
import "./AboutUsJumbotron.scss";

const AboutUsJumbotron = () => {
  const { t } = useTranslation(["translation", "de"]);
  return (
    <Container fluid className="about-us-jumbotron">
      <Row className="about-us-jumbotron__row flex-column flex-sm-row">
        <Col className="about-us-jumbotron__row__col">
          <h1 className="about-us-jumbotron__row__col__title">{t('booking.title')}</h1>
          <p className="about-us-jumbotron__row__col__subtitle">{t('booking.subtitle')}</p>
        </Col>
        <Col className="about-us-jumbotron__row__icon">
          <AboutUsJumboIcon />
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUsJumbotron;