import { Col, Container, Row } from "react-bootstrap";
import "./OurCoreValues.scss";
import integrity from "../../assets/images/integrity.png";
import excellence from "../../assets/images/excellence.png";
import customer from "../../assets/images/customer.png";
import { useTranslation } from "react-i18next";

const OurCoreValues = () => {
  const { t } = useTranslation(["translation", "de"]);
  return (
    <Container className="our-core-values">
      <Row className="our-core-values-title-body">
        <Col>
          <span className="coreValue-title">{t('coreValue.title')}</span>
        </Col>
        <Col>
          <span className="coreValue-subtitle">{t('coreValue.subtitle')}</span>
        </Col>
      </Row>
      <Row className="our-core-values-body">
        <Col className="our-core-values-content" sm={3}>
          <img className="w-100" src={integrity} alt="customer" />
          <div className="our-core-text-body">
            <span className="our-core-text-title">{t('coreValue.value1.title')}</span>
            <span className="our-core-text-subtitle">{t('coreValue.value1.subtitle')}</span>
          </div>
        </Col>
        <Col className="our-core-values-content" sm={3}>
          <img className="w-100" src={customer} alt="customer" />
          <div className="our-core-text-body">
            <span className="our-core-text-title">{t('coreValue.value2.title')}</span>
            <span className="our-core-text-subtitle">{t('coreValue.value2.subtitle')}</span>
          </div>
        </Col>
        <Col className="our-core-values-content" sm={3}>
          <img className="w-100" src={excellence} alt="customer" />
          <div className="our-core-text-body">
            <span className="our-core-text-title">{t('coreValue.value4.title')}</span>
            <span className="our-core-text-subtitle">{t('coreValue.value4.subtitle')}</span>
          </div>
        </Col>
      </Row>


    </Container>
  );
};

export default OurCoreValues;