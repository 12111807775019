export const WebDevIcon = () => (
  <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="27.5" cy="27.5" r="27.5" fill="#3D43D2" fill-opacity="0.3" />
    <circle cx="27.5" cy="27.5002" r="22.55" fill="#E2E3F8" />
    <path d="M20.1082 37.3918C18.7378 37.9937 17.4363 38.7401 16.245 39.6161C18.7447 42.1799 22.0019 43.8911 25.5002 44.4902C23.207 42.487 21.4028 40.1023 20.1082 37.3918Z" fill="#333333" />
    <path d="M11.7542 31.0458C12.2913 33.8403 13.517 36.4413 15.3763 38.6621C16.6847 37.6966 18.1034 36.8744 19.5977 36.2181C18.6749 33.9477 18.1447 31.5403 18.0138 29.0564L14.0611 29.0584C13.82 30.1444 12.8904 30.9605 11.7542 31.0458Z" fill="#333333" />
    <path d="M27.7102 29.0517L19.2951 29.0565C19.4259 31.3738 19.9286 33.6187 20.7825 35.7383C22.9999 34.9174 25.3275 34.4526 27.7102 34.3562V29.0517Z" fill="#333333" />
    <path d="M27.7094 44.6299V35.6371C25.5127 35.728 23.3573 36.1577 21.3052 36.911C22.7513 39.9286 24.9618 42.5895 27.7094 44.6299Z" fill="#333333" />
    <path d="M19.8388 17.4315C20.0591 17.4315 20.2726 17.4687 20.4792 17.5231C21.7325 15.1232 23.4403 12.9464 25.4993 11.1381C21.698 11.7868 18.2273 13.7225 15.6519 16.6465C16.3956 17.2304 17.1738 17.7572 17.9795 18.2358C18.4477 17.7435 19.1088 17.4315 19.8388 17.4315Z" fill="#333333" />
    <path d="M19.9078 22.5509C19.474 24.2477 19.2536 25.9975 19.2536 27.7749L27.71 27.7701V22.4119C25.7474 22.332 23.8261 21.9938 21.9737 21.4126C21.5261 22.084 20.7686 22.5275 19.9078 22.5509Z" fill="#333333" />
    <path d="M17.2775 19.9956C17.2775 19.7739 17.3119 19.5632 17.367 19.3579C16.4924 18.8422 15.6454 18.272 14.8466 17.6377C12.9942 20.0906 11.8786 22.9299 11.5412 25.9392C11.555 25.9392 11.5618 25.9371 11.5618 25.9371C12.7256 25.9371 13.6966 26.7173 14.0133 27.7778L17.973 27.7751C17.973 25.8979 18.2071 24.051 18.6616 22.2605C17.8421 21.8336 17.2775 20.9845 17.2775 19.9956Z" fill="#333333" />
    <path d="M35.9181 38.7551C35.8424 38.7551 35.7735 38.74 35.7046 38.7338C34.4789 40.9071 32.895 42.8518 30.9669 44.5293C34.6717 43.9529 38.0942 42.1453 40.6766 39.3949C39.8089 38.7813 38.8861 38.2428 37.922 37.7704C37.4538 38.3661 36.7376 38.7551 35.9181 38.7551Z" fill="#333333" />
    <path d="M38.4803 36.1921C38.4803 36.3312 38.4597 36.4648 38.439 36.5977C39.5202 37.1238 40.5531 37.7318 41.5241 38.4253C43.7001 35.7279 44.981 32.4914 45.2289 29.042L38.501 29.0448C38.4046 30.8332 38.1016 32.5823 37.5989 34.2681C38.136 34.7384 38.4803 35.4215 38.4803 36.1921Z" fill="#333333" />
    <path d="M45.2698 27.7617C45.2629 24.1891 44.1473 20.7838 42.0539 17.8964C40.6077 18.9982 39.0308 19.9182 37.3643 20.633C38.1356 22.9248 38.535 25.3226 38.535 27.7651L45.2698 27.7617Z" fill="#333333" />
    <path d="M22.4007 19.9955C22.4007 20.0651 22.387 20.1305 22.3801 20.1987C24.1085 20.7372 25.8921 21.0512 27.7101 21.1304V10.9999C25.1346 12.913 23.0481 15.3576 21.6019 18.141C22.0909 18.6079 22.4007 19.2635 22.4007 19.9955Z" fill="#333333" />
    <path d="M28.991 21.1445C31.2979 21.1032 33.5704 20.6873 35.7327 19.9036C34.3417 16.4584 32.0141 13.4408 28.991 11.1449V21.1445Z" fill="#333333" />
    <path d="M30.9669 11.0991C33.5905 13.3861 35.6358 16.2508 36.9235 19.4302C38.4592 18.765 39.9191 17.9111 41.2619 16.8891C38.6107 13.76 34.9816 11.7216 30.9669 11.0991Z" fill="#333333" />
    <path d="M28.991 27.77L37.2546 27.7652C37.2546 25.4879 36.8896 23.2485 36.1734 21.1048C33.8665 21.9421 31.4494 22.387 28.991 22.4283V27.77Z" fill="#333333" />
    <path d="M33.357 36.1921C33.357 36.1783 33.357 36.1652 33.357 36.1522C31.9315 35.8271 30.4716 35.6453 28.991 35.6199V44.4839C31.2291 42.7857 33.0884 40.6744 34.4656 38.3014C33.7977 37.8393 33.357 37.068 33.357 36.1921Z" fill="#333333" />
    <path d="M35.9187 33.6275C36.0908 33.6275 36.263 33.6462 36.4282 33.6799C36.8621 32.1794 37.1306 30.6286 37.2202 29.0461L28.991 29.0509V34.3417C30.5887 34.3671 32.1656 34.5641 33.7081 34.9194C34.1489 34.1509 34.9684 33.6275 35.9187 33.6275Z" fill="#333333" />
    <path d="M17.2779 19.9952C17.2779 20.9841 17.8425 21.8332 18.662 22.2601C19.0201 22.444 19.4127 22.5576 19.8396 22.5576C19.8603 22.5576 19.8878 22.5514 19.9085 22.5507C20.7693 22.5273 21.5268 22.0838 21.9744 21.4124C22.2085 21.0605 22.3462 20.6446 22.3806 20.1984C22.3875 20.1302 22.4013 20.0648 22.4013 19.9952C22.4013 19.2632 22.0914 18.6077 21.6025 18.1408C21.2926 17.846 20.907 17.6339 20.48 17.523C20.2734 17.4686 20.06 17.4315 19.8396 17.4315C19.1097 17.4315 18.4486 17.7434 17.9803 18.2358C17.6842 18.5464 17.4707 18.9299 17.3674 19.3576C17.3123 19.5628 17.2779 19.7735 17.2779 19.9952Z" fill="#515EF9" />
    <path d="M11.5617 25.9369C11.5617 25.9369 11.5548 25.939 11.5411 25.939C10.1363 25.9507 9 27.0931 9 28.5014C9 29.9172 10.15 31.0645 11.5617 31.0645C11.6306 31.0645 11.6925 31.0507 11.7545 31.0459C12.8908 30.9605 13.8204 30.1444 14.0614 29.0584C14.1028 28.878 14.1303 28.6935 14.1303 28.5014C14.1303 28.2479 14.0821 28.0083 14.0132 27.7776C13.6964 26.7171 12.7255 25.9369 11.5617 25.9369Z" fill="#BDBDBD" />
    <path d="M33.3563 36.1918C33.3563 37.0678 33.797 37.839 34.4649 38.3011C34.823 38.5469 35.2431 38.6943 35.7045 38.7335C35.7733 38.7397 35.8422 38.7549 35.918 38.7549C36.7375 38.7549 37.4536 38.3658 37.9219 37.7702C38.1904 37.4348 38.3695 37.0368 38.4383 36.5974C38.459 36.4645 38.4796 36.3309 38.4796 36.1918C38.4796 35.4212 38.1353 34.7381 37.5982 34.2678C37.2677 33.9813 36.8751 33.7692 36.4275 33.6797C36.2623 33.646 36.0901 33.6273 35.918 33.6273C34.9677 33.6273 34.1482 34.1507 33.7074 34.9192C33.494 35.2849 33.3631 35.7015 33.3563 36.1519C33.3563 36.165 33.3563 36.178 33.3563 36.1918Z" fill="#515EF9" />
  </svg>

)