import AboutUsJumbotron from "./AboutUsJumbotron";
import AboutUsStatus from "./AboutUsStatus";
import OurCoreValues from "./OurCoreValues";
import WhoWeAre from "./WhoWeAre";
import "./AboutUs.scss";
import MeetTheTeam from "./MeetTheTeam";
import ConnectWithUs from "./ConnectWithUs";
import Footer from "../Footer";
import NavBar from "../Header";

const AboutUs = () => {
  return (
    <div className="about-us">
      <NavBar />
      <AboutUsJumbotron />
      <AboutUsStatus />
      <OurCoreValues />
      <WhoWeAre />
      <MeetTheTeam />
      <ConnectWithUs />
      <Footer />
    </div >
  );
};

export default AboutUs;