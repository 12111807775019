export const MobileDevIcon = () => (
  <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="27.5" cy="27.5" r="27.5" fill="#ECECFB" />
    <circle cx="27.5" cy="27.5002" r="22.55" fill="white" />
    <path d="M19.3763 12.9688H35.4771V39.7101H19.3763V12.9688ZM27.427 43.8624C26.6993 43.8624 26.1077 43.2671 26.1077 42.5444C26.1077 41.8146 26.6993 41.2194 27.427 41.2194C28.1554 41.2194 28.7457 41.8146 28.7457 42.5444C28.7457 43.2671 28.1554 43.8624 27.427 43.8624ZM18.1523 45.9526H36.7022C37.8904 45.9526 38.8541 44.989 38.8541 43.7986V12.1539C38.8541 10.9636 37.8904 9.99987 36.7022 9.99987H18.1523C16.9638 9.99987 16 10.9636 16 12.1539V43.7986C16 44.989 16.9638 45.9526 18.1523 45.9526Z" fill="#333333" />
    <path d="M33.9594 28.295H27.7254V27.4801H33.9594V28.295ZM33.9594 29.6909H27.7254V28.876H33.9594V29.6909ZM33.9594 31.0867H27.7254V30.2789H33.9594V31.0867ZM33.9594 34.502H27.7254V33.6871H33.9594V34.502ZM33.9594 35.905H27.7254V35.0902H33.9594V35.905ZM27.7254 31.6748H30.9728V32.4897H27.7254V31.6748ZM27.5185 25.1064H30.6369V26.3039H27.5185V25.1064ZM27.3236 26.3039H24.2074V25.1064H27.3236V26.3039ZM26.9127 32.9857H20.8948V27.3313H26.9127V32.9857ZM20.8948 25.1064H24.0118V26.3039H20.8948V25.1064ZM33.9488 25.1064V26.3039H30.8318V25.1064H33.9488ZM20.8948 14.648H33.9488V24.2632H20.8948V14.648ZM35.4771 12.9686H19.3763V39.71H35.4771V12.9686Z" fill="white" />
    <path d="M27.427 41.2194C26.6993 41.2194 26.1077 41.8145 26.1077 42.5443C26.1077 43.2671 26.6993 43.8623 27.427 43.8623C28.1554 43.8623 28.7457 43.2671 28.7457 42.5443C28.7457 41.8145 28.1554 41.2194 27.427 41.2194Z" fill="#C8C8C8" />
    <path d="M33.9487 14.648H20.8948V24.2632H33.9487V14.648Z" fill="#515EF9" />
    <path d="M24.0118 25.1067H20.8948V26.3042H24.0118V25.1067Z" fill="#BDBDBD" />
    <path d="M20.8948 32.9858H26.9126V27.3315H20.8948V32.9858Z" fill="#BDBDBD" />
    <path d="M24.2073 26.3042H27.3236V25.1067H24.2073V26.3042Z" fill="#BDBDBD" />
    <path d="M30.6368 25.1067H27.5184V26.3042H30.6368V25.1067Z" fill="#BDBDBD" />
    <path d="M27.7253 28.2954H33.9593V27.4806H27.7253V28.2954Z" fill="#515EF9" />
    <path d="M27.7253 29.6909H33.9593V28.8761H27.7253V29.6909Z" fill="#515EF9" />
    <path d="M27.7253 31.0869H33.9593V30.2791H27.7253V31.0869Z" fill="#515EF9" />
    <path d="M30.9727 31.6754H27.7253V32.4902H30.9727V31.6754Z" fill="#515EF9" />
    <path d="M27.7253 34.502H33.9593V33.6871H27.7253V34.502Z" fill="#515EF9" />
    <path d="M27.7253 35.9053H33.9593V35.0904H27.7253V35.9053Z" fill="#515EF9" />
    <path d="M33.9487 26.3042V25.1067H30.8317V26.3042H33.9487Z" fill="#BDBDBD" />
  </svg>

)