import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resources } from '../i18n'

i18n.use(initReactI18next).init({
  resources,
  lng: 'de',
  fallbackLng: ['en', 'de'],

  interpolation: {
    escapeValue: false,
  },
})

export default i18n
