import { Button, Col, Container, Row } from "react-bootstrap";
import './Booking.scss';
import { MainHomeIcon } from "../assets/icons/main-home-icon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


const Booking = () => {
  const { t } = useTranslation(["translation", "de"])
  const navigate = useNavigate();

  return (
    <>
      <div className="booking-container" >
        <Container className="booking-content">
          <Row>
            <Col md={6} className="booking-container__left col-md-6 col-12   order-2 order-md-1">
              <h1 className="booking-container__left__title">{t('booking.title')}</h1>
              <p className="booking-container__left__subtitle">{t('booking.subtitle')}</p>
              <Button onClick={() => navigate('contact-us')} className="booking-container__left__button">{t('booking.button')}</Button>
            </Col>
            <Col md={6} className="booking-container__right col-md-6 col-12  order-1 order-md-2">
              <span className="companypicture">
                <MainHomeIcon />
              </span>

            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Booking;