import { Stepper, Step, StepLabel, Typography, StepContent } from '@mui/material';
import Box from "@mui/material/Box";
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContactTextIcon from '../assets/icons/contact-text-icon';
import './ContractProcedure.scss';

const ContractProcedure = () => {

  const { t } = useTranslation(["translation", "de"])

  const steps = [
    {
      label: t('whatsNext.step1.title'),
      position: 'left',
      description: t('whatsNext.step1.subtitle'),

    },
    { label: t('whatsNext.step2.title'), position: 'left', description: t('whatsNext.step2.subtitle') },
    { label: t('whatsNext.step3.title'), position: 'left', description: t('whatsNext.step3.subtitle') },
    {
      label: t('whatsNext.step4.title'),
      position: 'left',
      description: t('whatsNext.step4.subtitle'),
    },
    { label: t('whatsNext.step5.title'), position: 'left', description: t('whatsNext.step5.subtitle') },
    { label: t('whatsNext.step6.title'), position: 'left', description: t('whatsNext.step6.subtitle') },
    { label: t('whatsNext.step7.title'), position: 'left', description: t('whatsNext.step7.subtitle') },
  ]
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = (index: Number) => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    if (index === steps.length) {
      setActiveStep(0);
    }
  };

  return (
    <Container className='contract-procedure'>
      <Row className='pb-5 pt-5'>
        <Col className='contract-procedure-icon col-12 col-md-6'>
          <ContactTextIcon />
        </Col>
        <Col className='contract-procedure-content-body col-12 col-md-6'>
          <Row className='contract-procedure-content'>
            <Col className="col-12 contract-procedure-title-col pt-5">
              <span className="contract-procedure-title">{t('whatsNext.title')}</span>
            </Col>
            <Col className='pb-5'>
              <Box sx={{ maxWidth: 400 }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel
                        className="step-label"
                        onClick={() => handleNext(index + 1)}
                        optional={
                          index === 2 ? (
                            <Typography variant="caption">next step</Typography>
                          ) : null
                        }
                      >
                        {step.label}
                      </StepLabel>
                      <StepContent>
                        <Typography className="step-description" >
                          {step.description}
                        </Typography>

                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Col>

          </Row>

        </Col>
      </Row>
    </Container>

  )
}

export default ContractProcedure;