import { Checkbox, TextField } from "@mui/material";
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import "./ContactForm.scss"
import { useState } from "react";
import axios from "axios";

interface Props {
  className?: string;
  children: React.ReactNode;
}

const ContactForm: React.FC<Props> = ({ className, children }) => {
  const { t } = useTranslation(["translation", "de"])
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
    dataProtectionTermsAccept: false,
  })


  const handleFormSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    // Make the POST request
    axios.post('http://localhost:3000/send-email', formData)
      .then(response => {
        // Handle the response
        console.log(response.data);
        // Do something with the response, e.g., show a success message
      })
      .catch(error => {
        // Handle the error
        console.error(error);
        // Display an error message to the user
      });
  };
  const handlleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  return (
    <div className={className}>
      <span className="contact-form-main-title">{t('contactForm.title')}</span>
      <Row>
        <span className="contact-form-field-title">{t('contactForm.title1')}<hr style={{ textAlign: 'left', width: '10%' }}></hr></span>
        <Col className="col-12 col-md-6">
          <Row className="contact-form-field">
            <Col>
              <TextField
                required
                id="outlined-required"
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handlleFormChange}
              />
            </Col>
            <Col>
              <TextField
                required
                id="outlined-required"
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handlleFormChange}
              />
            </Col>
          </Row>
          <Row className="contact-form-field">
            <Col>
              <TextField
                required
                id="outlined-required"
                label="Email"
                name="email"
                value={formData.email}
                onChange={handlleFormChange}
              />
            </Col>
            <Col>
              <TextField
                required
                id="outlined-required"
                label="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handlleFormChange}
              />
            </Col>

          </Row>
          <Row className="contact-form-field-message-container">
            <Col>
              <TextField
                id="outlined-multiline-static"
                label="Your Message"
                name="message"
                fullWidth
                multiline
                rows={6}
                value={formData.message}
                onChange={handlleFormChange}
              />
            </Col>
            <Col className="text-left">
              <Checkbox
                defaultChecked
                name="dataProtectionTermsAccept"
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <span className="contact-form-field-message-container-text">{t('contactForm.subtitle')}</span>
            </Col>
            <Col>
              <div className="contact-form-button">
                <span className="contact-form-text" onClick={handleFormSubmit}>{t('contactForm.button')}</span>
              </div>
            </Col>
          </Row>

        </Col>
        <Col>{children}</Col>
      </Row>
    </div>
  )
}

export default ContactForm