import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./ConnectWithUs.scss";
import facebook from "../../assets/images/facebook.png";
import linkedln from "../../assets/images/linkedln.png";
import twitter from "../../assets/images/twitter.png";
import socialMedia from "../../assets/images/social-media-influencer.png";

const ConnectWithUs = () => {
  const { t } = useTranslation(["translation", "de"]);
  return (
    <Container className="connect-with-us">
      <Row className="connect-with-us__content">
        <Col className="col-12 col-md-6">
          <Row className="connect-with-us__content_body">
            <Col>
              <span className="connect-with-us__content__title">
                {t("stayConnected.title")}
              </span>
            </Col>
            <Col>
              <span className="connect-with-us__content__subtitle">
                {t("stayConnected.subtitle")}
              </span>
            </Col>
            <Col className="connect-with-us__content__body-item">
              <div className="connect-with-us__content__item">
                <a href="https://www.facebook.com/">
                  <img className="w-100 h-100 img-fluid" src={facebook} alt=" facebook" />
                </a>
              </div>
              <div className="connect-with-us__content__item">
                <a href="https://www.instagram.com/">
                  <img className="w-100 h-100 img-fluid" src={linkedln} alt=" instagram" />
                </a>
              </div>
              <div className="connect-with-us__content__item">
                <a href="https://twitter.com/">
                  <img className="w-100 h-100 img-fluid" src={twitter} alt=" twitter" />
                </a>
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="col-12 col-md-6">
          <span><img className="w-100 h-100 img-fluid" src={socialMedia} alt="socialMedia" /></span>
        </Col>
      </Row>
    </Container>
  );
};

export default ConnectWithUs;