import { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import "./Services.scss";
import work from "../assets/images/work.png";
import Plus from "../assets/icons/plus-icon";
import MinusIcon from "../assets/icons/minus-icon";
import ContactForm from "./ContactForm";
import projectTeam from "../assets/images/project-team.png";



const Services = () => {
  const { t } = useTranslation(["translation", "de"]);
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container className="services">
      <span className="services-title">{t('OurServices.title')}<hr className='horizontal-line' style={{ textAlign: 'left', width: '10%' }}></hr></span>
      <Row>
        <Col>
          <img src={work} alt="work" />
        </Col>
        <Col>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              expandIcon={expanded === 'panel1' ? <MinusIcon /> : <Plus />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className="ourservice-title" sx={{ width: '50%', flexShrink: 0 }}>
                {t('OurServices.title1')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {t('OurServices.subtitle1')}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              expandIcon={expanded === 'panel2' ? <MinusIcon /> : <Plus />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className="ourservice-title" sx={{ width: '50%', flexShrink: 0 }}>{t('OurServices.title2')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {t('OurServices.subtitle2')}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary
              expandIcon={expanded === 'panel3' ? <MinusIcon /> : <Plus />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography className="ourservice-title" sx={{ width: '50%', flexShrink: 0 }}>
                {t('OurServices.title3')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {t('OurServices.subtitle3')}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary
              expandIcon={expanded === 'panel4' ? <MinusIcon /> : <Plus />}
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography className="ourservice-title" sx={{ width: '50%', flexShrink: 0 }}>{t('OurServices.title4')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {t('OurServices.subtitle4')}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary
              expandIcon={expanded === 'panel5' ? <MinusIcon /> : <Plus />}
              aria-controls="panel5bh-content"
              id="panel5bh-header"
            >
              <Typography className="ourservice-title" sx={{ width: '50%', flexShrink: 0 }}>{t('OurServices.title5')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {t('OurServices.subtitle5')}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
            <AccordionSummary
              expandIcon={expanded === 'panel6' ? <MinusIcon /> : <Plus />}
              aria-controls="panel6bh-content"
              id="panel6bh-header"
            >
              <Typography className="ourservice-title" sx={{ width: '50%', flexShrink: 0 }}>{t('OurServices.title6')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {t('OurServices.subtitle6')}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
            <AccordionSummary
              expandIcon={expanded === 'panel7' ? <MinusIcon /> : <Plus />}
              aria-controls="panel7bh-content"
              id="panel6bh-header"
            >
              <Typography className="ourservice-title" sx={{ width: '50%', flexShrink: 0 }}>{t('OurServices.title7')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {t('OurServices.subtitle7')}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Col>
      </Row>
      <Row className="how-we-do-it">
        <span className="how-we-do-it-title"> {t('HowWeDoIt.title')}</span>
        <Row className="how-we-do-it-cards">
          <Col xs={12} sm={3}>
            <Card>
              <Card.Body>
                <Card.Title className="how-we-do-it-steps-title">{t('HowWeDoIt.step1.title')}</Card.Title>
                <Card.Text>
                  {t('HowWeDoIt.step1.subtitle')}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={3}>
            <Card>
              <Card.Body>
                <Card.Title className="how-we-do-it-steps-title">{t('HowWeDoIt.step2.title')}</Card.Title>
                <Card.Text>
                  {t('HowWeDoIt.step2.subtitle')}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={3}>
            <Card>
              <Card.Body>
                <Card.Title className="how-we-do-it-steps-title">{t('HowWeDoIt.step3.title')}</Card.Title>
                <Card.Text>
                  {t('HowWeDoIt.step3.subtitle')}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Row>
      <Row>
        <ContactForm className="contact-form">  <span><img className="w-100" src={projectTeam} alt="projectTeam" /></span> </ContactForm>

      </Row>
    </Container>
  );
};

export default Services;