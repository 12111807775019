import { Container, Col, Row } from "react-bootstrap"
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import { ClientFeedbackIcon } from "../assets/icons/client-feedback-icon";
import { useTranslation } from "react-i18next";
import './FeedBack.scss';

const FeedBack = () => {

  const { t } = useTranslation(["translation", "de"])

  const steps = [
    {
      label: t('feedBack.step1.name'),
      position: 'left',
      description: t('feedBack.step1.feedback'),
    },
    { label: t('feedBack.step2.name'), position: 'left', description: t('feedBack.step2.feedback') },
    { label: t('feedBack.step3.name'), position: 'left', description: t('feedBack.step3.feedback') },
  ]
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = (index: Number) => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    if (index === steps.length) {
      setActiveStep(0);
    }
  };

  return (
    <Container fluid className="feedback">
      <Row>
        <Col className="feedback-icon">
          <ClientFeedbackIcon />
        </Col>
        <Col>
          <Row>
            <Col className="col-12 feedback-title-col">
              <span className="feedback-title">{t('feedBack.title')}</span>
            </Col>
            <Col>
              <Box sx={{ maxWidth: 400 }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel
                        className="step-label"
                        onClick={() => handleNext(index + 1)}
                        optional={
                          index === 2 ? (
                            <Typography variant="caption">last step</Typography>
                          ) : null
                        }
                      >
                        {step.label}
                      </StepLabel>
                      <StepContent>
                        <Typography className="step-description" >
                          {step.description}
                        </Typography>

                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Col>

          </Row>

        </Col>
      </Row>
    </Container>
  )
}

export default FeedBack