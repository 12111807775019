export const BrandIcon = () => (
  <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="27.5" cy="27.5" r="27.5" fill="#3D43D2" fill-opacity="0.3" />
    <circle cx="27.5" cy="27.5002" r="22.55" fill="#E2E3F8" />
    <g clip-path="url(#clip0_48_1343)">
      <path d="M42.076 18.1938L38.314 14.3988C38.182 14.2668 38.017 14.2668 37.885 14.3988C37.7529 14.5308 37.7529 14.6958 37.885 14.8279L41.581 18.5239L41.4489 25.3879L30.8229 36.0139C30.7239 36.1129 30.7239 36.3109 30.8229 36.4098C30.922 36.5089 31.12 36.5089 31.2189 36.4098L41.9439 25.6848C42.0098 25.6189 42.0098 25.5528 42.0429 25.4868L42.1749 18.3918C42.1749 18.3258 42.142 18.2598 42.0759 18.1939L42.076 18.1938Z" fill="#3D43D2" />
      <path d="M28.975 37.8618L27.886 38.9509L15.049 26.1139L23.959 17.2039C24.091 17.0718 24.091 16.9068 23.959 16.7748C23.8269 16.6428 23.6619 16.6428 23.5299 16.7748L14.4549 25.9158L11.1219 29.2488C10.9899 29.3478 10.9899 29.5458 11.1219 29.6447L24.1899 43.0427C24.2559 43.1087 24.3219 43.1418 24.3879 43.1418C24.4538 43.1418 24.5199 43.1088 24.5859 43.0758L28.0509 39.5778L29.3379 38.2908C29.4699 38.1588 29.4699 37.9938 29.3379 37.8617C29.2719 37.7297 29.074 37.7297 28.9749 37.8617L28.975 37.8618ZM24.388 42.4158L11.716 29.4468L14.653 26.5098L21.055 32.9448L27.49 39.3468L24.388 42.4158Z" fill="#3D43D2" />
      <path d="M26.467 14.5968C26.533 14.5968 26.632 14.5639 26.665 14.4978L28.645 12.5178L35.542 12.4188L36.169 13.0458C36.301 13.1778 36.466 13.1778 36.5981 13.0458C36.7301 12.9138 36.7301 12.7488 36.5981 12.6167L35.8721 11.9238C35.8062 11.8578 35.7401 11.8247 35.6741 11.8247L28.4799 11.9568C28.4139 11.9568 28.3478 11.9898 28.2819 12.0228L26.2359 14.0687C26.1039 14.1678 26.1039 14.3328 26.203 14.4647L26.2359 14.4977C26.3019 14.5638 26.3679 14.5968 26.4669 14.5968L26.467 14.5968Z" fill="#3D43D2" />
      <path d="M33.628 20.3721C33.397 20.108 33.2321 19.778 33.199 19.415C33.199 19.25 33.034 19.151 32.9019 19.151C32.7369 19.151 32.6379 19.283 32.6379 19.448C32.6709 19.943 32.9019 20.405 33.2319 20.735C34.0569 21.56 35.3769 21.56 36.2019 20.735C37.0269 19.91 37.0269 18.59 36.2019 17.765C35.6079 17.171 34.684 16.973 33.8919 17.303C33.7599 17.369 33.661 17.534 33.7269 17.666C33.7929 17.798 33.9579 17.8969 34.0899 17.831C34.8819 17.501 35.7729 17.831 36.1029 18.623C36.3669 19.2171 36.2349 19.91 35.7399 20.3721C35.179 20.9991 34.222 20.966 33.628 20.3721L33.628 20.3721Z" fill="#3D43D2" />
      <path d="M41.053 28.655C40.888 28.688 40.8221 28.853 40.855 28.985C41.416 31.361 40.4921 34.331 38.578 36.245C36.07 38.753 32.539 38.8191 29.602 36.476C27.589 34.8919 26.8629 31.658 27.622 27.83C27.655 27.665 27.556 27.5329 27.391 27.5C27.226 27.467 27.094 27.5659 27.061 27.7309C26.269 31.7569 27.061 35.1889 29.239 36.9379C30.592 38.06 32.308 38.687 34.09 38.72C35.938 38.72 37.687 37.9609 38.974 36.6079C41.02 34.562 42.01 31.3609 41.416 28.8199C41.35 28.6879 41.185 28.6219 41.053 28.6549L41.053 28.655Z" fill="#3D43D2" />
      <path d="M11.287 19.5141C14.95 17.237 19.108 15.9831 23.431 15.8841C30.361 15.785 34.255 19.5141 34.288 19.5471C34.354 19.613 34.4201 19.613 34.486 19.613C34.552 19.613 34.651 19.5801 34.684 19.514C34.783 19.4149 34.783 19.217 34.684 19.118C34.519 18.986 30.691 15.29 23.695 15.29H23.431C19.009 15.3891 14.719 16.676 10.99 19.019C10.8579 19.1181 10.825 19.2831 10.924 19.415C10.957 19.58 11.155 19.613 11.287 19.514L11.287 19.5141Z" fill="#3D43D2" />
    </g>
    <defs>
      <clipPath id="clip0_48_1343">
        <rect width="33" height="33" fill="white" transform="translate(10 11)" />
      </clipPath>
    </defs>
  </svg>
)
