import { Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import InstagramIcon from "../assets/icons/instagram-icon";
import LinkedlnIcon from "../assets/icons/linkedln-icon";
import MailIcon from "../assets/icons/mail-icon";
import TelePhoneIcon from "../assets/icons/telephone-icon";
import TwitterIcon from "../assets/icons/twitter-icon";
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import HizelusLogo from "../assets/images/hizelus-eng-logo.png";
import './Footer.scss'
import { useNavigate } from "react-router-dom";
import BackToTopIcon from "../assets/icons/backToTop-icon";

const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["translation", "de"])

  const handleNavigate = (path: string) => {
    if (window.location.pathname !== path)
      navigate(path)
  }

  const date = new Date().getFullYear();

  return (
    <Container fluid className="footer">
      <div className="footer-content">
        <Row className="footer-body">
          <Col className="footer-body-left">
            <Row className="footer-body-left-content">
              <Col className="logo"><span><img src={HizelusLogo} alt='logo' className='img-fluid w-100 h-100' /> </span> </Col>
              <Col>
                {t('footer.title')}
              </Col>
              <Col className="footer-body-left-icons">
                <a href='https://www.linkedin.com/in/hilary-ogalagu/' target="_blank" rel="noreferrer" className="linkedln-icon"><LinkedlnIcon /></a>
                <a href='https://www.instagram.com/weenix1/' target="_blank" rel="noreferrer" className="instagram-icon"><InstagramIcon /> </a>
                <span className="twitter-icon"><TwitterIcon /> </span>
              </Col>
              <Col>
                <div onClick={() => handleNavigate('/contact-us')
                } className="footer-body-left-button">
                  <span className="footer-body-left-text">Book a Call</span>
                </div>
              </Col>
            </Row>
          </Col>

          <Col className="footer-body-middle">
            <span className="footer-body-middle-title">{t('footer.userfulLinks.title')}</span>
            <div className="footer-body-middle-content">
              <span onClick={() => navigate('/')}>{t('footer.userfulLinks.home')}</span>
              <span onClick={() => navigate('/about-us')}>{t('footer.userfulLinks.about')}</span>
              <span onClick={() => navigate('/services')}>{t('footer.userfulLinks.services')}</span>
              <span onClick={() => navigate('/contact-us')}>{t('footer.userfulLinks.contact')}</span>
              <span onClick={() => navigate('/faqs')}>{t('footer.userfulLinks.faqs')}</span>
            </div>
          </Col>

          <Col className="footer-body-right">
            <span className="footer-body-right-title">{t('footer.contact.title')}</span>
            <div className="footer-body-right-content">
              <span className="footer-body-right-address"><FmdGoodIcon /> {t('footer.contact.address')}</span>
              <span onClick={() => {
                window.location.href = 'mailto:hilary@hizelus.com';
              }} className="footer-body-right-email text-decoration-underline"><MailIcon /> {t('footer.contact.email')}</span>
              <span className="footer-body-right-phone"><TelePhoneIcon /> {t('footer.contact.phone')}</span>
            </div>
          </Col>
        </Row>
        <Row className="footer-bottom">
          <Col className="footer-bottom-left">
            <span className="footer-bottom-left-text">© {date} Hizelus Engineering. All rights reserved.</span>
          </Col>
          <Col className="footer-bottom-middle">
            <span className="footer-bottom-middle-text">Terms of Service</span>
          </Col>
          <Col className="footer-bottom-right">
            <span className="footer-bottom-right-text">Privacy Policy</span>
          </Col>
          <Col className="footer-bottom-end-button" onClick={() => window.scrollTo(0, 0)}>
            <span className="footer-bottom-end-text">Back to Top  <BackToTopIcon /></span>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default Footer;
