import Footer from "./Footer";
import Faqs from "./Faqs";
import QuestionsAccordion from "./QuestionsAccordion";
import "./QuestionsComponent.scss";

const QuestionsComponent = () => {

  return (
    <div className="question-component">
      <Faqs />
      <QuestionsAccordion />
      <Footer />
    </div>
  );
};

export default QuestionsComponent;