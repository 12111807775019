import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import team1 from "../../assets/images/team1.png";
import team2 from "../../assets/images/team2.png";
import "./WhoWeAre.scss";

const WhoWeAre = () => {
  const { t } = useTranslation(["translation", "de"]);
  return (
    <Container className="who-we-are">
      <Row>
        <Col className="col-12 col-md-6">
          <img className="w-100 h-100 img-fluid" src={team1} alt="team1" />
        </Col>
        <Col className="who-we-are-content col-12 col-md-6">
          <span className="who-we-are-content-title">{t('whoWeAre.title')}</span>
          <span className="who-we-are-content-subtitle">{t('whoWeAre.subtitle')}</span>
        </Col>
      </Row>
      <Row className="who-we-are-content-body">
        <Col className="who-we-are-content col-12 col-md-6">
          <span className="who-we-are-content-title">{t('whyWeDoIt.title')}</span>
          <span className="who-we-are-content-subtitle">{t('whyWeDoIt.subtitle')}</span>
        </Col>
        <Col className="col-12 col-md-6">
          <img className="w-100 h-100 img-fluid" src={team2} alt="team2" />
        </Col>
      </Row>
    </Container>
  );
};

export default WhoWeAre;