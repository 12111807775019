import { Col, Row } from "react-bootstrap";
import { motion, useMotionValue, useTransform, animate } from "framer-motion";
import { useEffect } from "react";
import "./AboutUsStatus.scss";
import { useTranslation } from "react-i18next";


const AboutUsStatus = () => {

  const { t } = useTranslation(["translation", "de"]);
  const count = useMotionValue(0);
  const rounded = useTransform(count, Math.round);

  const count1 = useMotionValue(0);
  const rounded1 = useTransform(count1, Math.floor);

  const count2 = useMotionValue(0);
  const rounded2 = useTransform(count2, Math.ceil);

  const count3 = useMotionValue(0);
  const rounded3 = useTransform(count3, value => Math.round(value * 10) / 10);

  useEffect(() => {
    const animation = animate(count, 10, { duration: 10 });
    const animation1 = animate(count1, 50, { duration: 10 });
    const animation2 = animate(count2, 8, { duration: 10 });
    const animation3 = animate(count3, 5, { duration: 10 });
    return () => {
      animation.stop();
      animation1.stop();
      animation2.stop();
      animation3.stop();
    };

    // return animation.stop;
  }, []);

  return (

    <Row className="aboutus-status-body">
      <Col className="aboutus-status-content">
        <span className="aboutus-status__title">Over <motion.span>{rounded}</motion.span>K+</span>
        <h1 className="aboutus-status_subtitle">{t('aboutUsStatus.customer')}</h1>
      </Col>
      <Col className="aboutus-status-content">
        <span className="aboutus-status__title"><motion.span>{rounded1}</motion.span>K+</span>
        <h1 className="aboutus-status_subtitle">{t('aboutUsStatus.team')}</h1>
      </Col>
      <Col className="aboutus-status-content">
        <h1 className="aboutus-status__title"><motion.span>{rounded2}</motion.span></h1>
        <h1 className="aboutus-status_subtitle">{t('aboutUsStatus.products')}</h1>
      </Col>
      <Col className="aboutus-status-content">
        <h1 className="aboutus-status__title"><motion.span>{rounded3}</motion.span>K+</h1>
        <h1 className="aboutus-status_subtitle">{t('aboutUsStatus.community')}</h1>
      </Col>
    </Row>

  );
};

export default AboutUsStatus;