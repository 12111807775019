
import './CoreServices.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { MobileDevIcon } from '../assets/icons/mobile-dev-icon';
import { DesignIcon } from '../assets/icons/design-icon';
import { WebDevIcon } from '../assets/icons/web-dev-icon';
import { BrandIcon } from '../assets/icons/branding-icon';
import { NextPageIcon } from '../assets/icons/next-page-icon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const CoreServices = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["translation", "de"])
  return (
    <div className="core-content" >
      <Container fluid>
        <h1 className='core-title'>{t('coreServices.title')}</h1>
        <p className='core-subtitle'>{t('coreServices.subtitle')}</p>
        <Row className="core-content__services col-12 ">
          <Col className="core-content__services__service1 ">
            <div className="core-content__services__service__icon1">
              <DesignIcon />
            </div>
            <div className="core-content__services__service__title1">
              <h3>{t('coreServices.service1.title')}</h3>
            </div>
            <div className="core-content__services__service__description1">
              <p>{t('coreServices.service1.description')}</p>
            </div>
          </Col>
          <Col className="core-content__services__service2">
            <div className="core-content__services__service__icon2">
              <MobileDevIcon />
            </div>
            <div className="core-content__services__service__title2">
              <h3>{t('coreServices.service2.title')}</h3>
            </div>
            <div className="core-content__services__service__description2">
              <p>{t('coreServices.service2.description')}</p>
            </div>
          </Col>
          <Col className="core-content__services__service3">
            <div className="core-content__services__service__icon3">
              <WebDevIcon />
            </div>
            <div className="core-content__services__service__title3">
              <h3>{t('coreServices.service3.title')}</h3>
            </div>
            <div className="core-content__services__service__description3">
              <p>{t('coreServices.service3.description')}</p>
            </div>
          </Col>
          <Col className="core-content__services__service4 ">
            <div className="core-content__services__service__icon4">
              <BrandIcon />
            </div>
            <div className="core-content__services__service__title4">
              <h3>{t('coreServices.service4.title')}</h3>
              <div className="core-content__services__service__description4">
                <p>{t('coreServices.service4.description')}</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row >
          <Col className='more-page' onClick={() => navigate('services')}>
            <span className='more-page-text'>{t('coreServices.more_services.text')}</span>
            <div className='next-icon'>
              <NextPageIcon />
            </div>
          </Col>
        </Row>
      </Container>
    </div>

  )
}

export default CoreServices